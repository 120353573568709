//
// variables.scss
//

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Libre+Baskerville&display=swap');

// Color
$white:                     #ffffff;

$primary:                   #006fb7; //3274f0
$secondary:                 #ee3054;
$success:                   #8dc351;
$warning:                   #f1af2d;
$info:                      #09a5be;
$danger:                    #e84142;
$dark:                      #18191a;
$black:                     #000000;
$muted:                     #2c2e30;
$light:                     #f7f8fa;

// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #8890a4;
$gray-600:                  #6c757d; 
$gray-700:                  #495057;

//Color Picker/Switcher
$default:                   #2f55d4;
$green:                     #6dc77a;
$red:                       #ff5b69;
$purple:                    #7952B3;
$skyblue:                   #32c2ec;
$skobleoff:                 #0f7173;
$cyan:                      #00c9a7;
$slateblue:                 #766df4;
$yellow:                    #f7961c;
$pantone:                   #c9ab81;
$coffee:                    #7d5034;

// Overlay
$overlay:                   rgba($black, 0.7);
$bg-overlay-white:          rgba($white, 0.5);
$linear-gradient:           linear-gradient(to bottom, rgba($black, 0.8) 0%, rgba($dark, 0.3) 25%, rgba($dark, 0.3) 35%, rgba($black, 0.8) 100%);
$gradient-overlay:          linear-gradient(to bottom, rgba($black, 0.5) 0%, rgba($black, 0.6) 25%, rgba($black, 0.7) 50%, rgba($black, 0.8) 100%);
$gradient-white-overlay:    linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0) 25%, rgba($white, 0) 50%, rgba($white, 0.5) 100%);
$card-overlay:              linear-gradient(to bottom, transparent 0%, transparent 25%, transparent 35%, rgba($black, 0.9) 100%);
$bg-gradient-primary:       linear-gradient(to left, darken($primary, 10%) 0%, darken($purple, 10%) 100%);

//Body Background
$shadow:                    0 0 3px rgba($dark,.15);
$shadow-md:                 0 5px 13px rgba($dark, .2);
$shadow-lg:                 0 10px 25px rgba($dark, 0.15);
$footer:                    lighten($black, 5%);


// Base font
$font-size-base:            15px;

$font-family-base:          'Poppins', sans-serif;
$font-family-secondary:     'Poppins', sans-serif;
$font-noto:                 'Noto Sans', sans-serif;
$font-libre:                'Libre Baskerville', serif;
$font-kaushan:              'Kaushan Script', cursive;


$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "blue": $primary,
    "footer": $footer
);

$heading-font-sizes: (
    "h1": 36px,
    "h2": 32px,
    "h3": 28px,
    "h4": 20px,
    "h5": 18px,
    "h6": 15px,
);

$display-font-sizes: (
    "display-1": 72px,
    "display-2": 64px,
    "display-3": 56px,
    "display-4": 48px,
    "display-5": 44px,
    "display-6": 40px,
);
.bg-gradient-overlay{
    background-color: #00000025 !important
}
.subFooter{
    background: #f7f8fa ;
}
.teamTitle{
    padding-left: 10px;
    padding-top: 5px;
    
}
.teamDesgination{
    padding-left: 10px;
}
.imageTeamDesgination{
    padding-bottom: 10px;
}
.justify-content-center {
    justify-content: center!important;
    align-items: center !important;
}
.img-fluid {
    padding: 20px !important;
}