//
// _bootstrap-custom.scss
//
.container {
    @media (min-width: 1400px) {
        max-width: 1140px !important;
    }
}

.row > * {
    position: relative;
}

@each $heading,
$size in $heading-font-sizes {
    #{$heading}, 
    .#{$heading} {
        font-size: $size !important;
    }
}

@each $heading,
$size in $display-font-sizes {
    .#{$heading} {
        font-size: $size !important;
        line-height: 1.65 !important;
    }
}

@each $name,
$value in $colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .bg-soft-#{$name} {
        background-color: rgba($value, 0.1) !important;
        border: 1px solid rgba($value, 0.1) !important;
        color: #{$value} !important;
        .border {
            border-color: $value !important;
        }
    }
    .text-#{$name} {
        color: #{$value} !important;
    }
    a {
        &.text-#{$name} {
            &:hover,
            &:focus {
                color: darken($value, 5%) !important;
            }
        }
    }
}

.bg-soft-light {
    color: $dark !important;
}

a {
    transition: all 0.5s ease;
}

.d-flex {
    .flex-1 {
        flex: 1;
    }
}

.bg-black {
    background: $black !important;
}

.bg-white-blur {
    backdrop-filter: blur(12px);
    background-color: rgba($white, 0.8);
}

//Video tag
video {
    &:focus {
        outline: none;
    }
}


.list-inline-item:not(:last-child) {
    margin-bottom: 5px !important;
    margin-right: 5px !important;
}

// Opacity
.opacity-05 {
    opacity: 0.05;
}

.opacity-075 {
    opacity: 0.075;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

// Rounded
.rounded {
    border-radius: 6px !important;
}
.rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}
.rounded-left {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}
.rounded-bottom {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.rounded-right {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.rounded-md {
    border-radius: 10px !important;
}
.rounded-lg {
    border-radius: 30px !important;
}

//Border
.border {
    border-color: $gray-200 !important;
}
.border-top {
    border-top-color: $gray-200 !important;    
}
.border-bottom {
    border-bottom-color: $gray-200 !important;    
}
.border-left {
    border-left-color: $gray-200 !important;    
}
.border-right {
    border-right-color: $gray-200 !important;    
}

//Small
.small, small {
    font-size: 90%;
}

//card
.card {
    background: transparent;
    .card-body {
        padding: 1.5rem;
    }
}

//classes of position absolute
.position-middle-bottom {
    position: absolute;
    bottom: 15px;
    left: 12px;
    right: 12px;
    text-align: center;
}

.pt-sm-150 {
    @media (max-width: 767px) {
        padding-top: 200px;
    }
}

//Display class
@media (max-width: 768px) {
    .display-1 {
        font-size: 56px !important;
    }
    .display-2 {
        font-size: 50px !important;
    }
    .display-3 {
        font-size: 42px !important;
    }
    .display-4 {
        font-size: 36px !important;
    }
    .display-5 {
        font-size: 32px !important;
    }
    .display-6 {
        font-size: 28px !important;
    }
}